<template>
    <div class="error-page">
        error page
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>

</style>